import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider, useDispatch } from "react-redux";
// lazy import of react app
const App = React.lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));
// creating main component here

const Main = () => {
  return (
    // adding loding in suspence
    <Provider store={store}>
      <Suspense fallback={<div className="loading" />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
};
// rendering the element to the dom
root.render(<Main />);
// converting react app into the PWA app
reportWebVitals();
