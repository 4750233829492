import { configureStore ,getDefaultMiddleware} from "@reduxjs/toolkit";


import userSlice from "./slices/userSlice";
import menuReducer from './slices/menuSlice';
import chatSlice from "./slices/chatSlice";
import socketSlice from "./slices/socketSlice";
import socketMiddleware from "./socketMiddleWare";
import notificationSlice from "./slices/notificationSlice";
export default configureStore({
  reducer: {
    userSlice,
    menuReducer,
    chatSlice,
    socketSlice,
    notificationSlice,
  },
  middleware:[socketMiddleware]
});
