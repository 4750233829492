// socketMiddleware.js

import { io } from "socket.io-client";
import { connectSocket } from "./slices/socketSlice";
import { appendChat, setActiveQuery } from "./slices/chatSlice";
import { addNotification } from "./slices/notificationSlice";
// import { SERVER_URL } from "../config/url";

let socketInstance = null;

const socketMiddleware = (store) => (next) => (action) => {
  if (action.type === connectSocket.type) {
    socketInstance = io("https://consultbackend.theharshgupta.com", { query: { id: action.payload } });

    socketInstance.on("msg", (message) => {
      console.log("Received message:", message);

      // Check if the current route is the chat page
      if (window.location.pathname.includes("chat")) {
        if (store.getState().chatSlice.activeQuery?._id === message.query_id) {
          store.dispatch(appendChat(message));
        } else {
          store.dispatch(addNotification(message));
        }
      } else {
        store.dispatch(addNotification(message));
      }
    });

    socketInstance.on("file", (query) => {
      console.log("Received file:", query);
      store.dispatch(setActiveQuery(query));
    });

    // Pass the socket instance to the next middleware or reducer
    next({ type: connectSocket.type, payload: { socket: socketInstance } });
  } else {
    // Pass other actions to the next middleware or reducer
    next(action);
  }
};

export default socketMiddleware;
