// socketSlice.js

import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  isConnected: false,
  socket: null,
};

// Create a slice
const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    connectSocket: (state, action) => {
      const { socket } = action.payload;
      state.socket = socket;
      state.isConnected = true;
    },
    disconnectSocket: (state) => {
      if (state.socket) {
        state.socket.disconnect();
        state.socket = null;
        state.isConnected = false;
      }
    },
    fileUploaded:(state,action) => {
      if (state.socket) {
        state.socket.emit('file',action.payload);
      }
    },
    sendMessage: (state, action) => {
      if (state.socket) {
        state.socket.emit('message', action.payload);
      }
    },
  },
});

// Export actions
export const { connectSocket, disconnectSocket, sendMessage ,fileUploaded} = socketSlice.actions;

// Export the reducer
export default socketSlice.reducer;
