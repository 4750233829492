import { createSlice } from '@reduxjs/toolkit';
import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from '../../constants/defaultValues';

const initialState = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === 'menu-default',
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    changeSelectedMenuHasSubItems: (state, action) => {
      state.selectedMenuHasSubItems = action.payload;
    },
    changeDefaultClassnames: (state, action) => {
      state.containerClassnames = action.payload;
    },
    addContainerClassname: (state, action) => {
      const { classname, strCurrentClasses } = action.payload;
      if (!strCurrentClasses.includes(classname)) {
        state.containerClassnames = `${strCurrentClasses} ${classname}`;
      }
    },
    clickOnMobileMenu: (state, action) => {
      const { strCurrentClasses } = action.payload;
      const currentClasses = strCurrentClasses
        ? strCurrentClasses
            .split(' ')
            .filter((x) => x !== '' && x !== 'sub-show-temporary')
        : '';
      let nextClasses = '';
      if (currentClasses.includes('main-show-temporary')) {
        nextClasses = currentClasses
          .filter((x) => x !== 'main-show-temporary')
          .join(' ');
      } else {
        nextClasses = `${currentClasses.join(' ')} main-show-temporary`;
      }
      state.containerClassnames = nextClasses;
      state.menuClickCount = 0;
    },
    setContainerClassnames: (state, action) => {
      let { clickIndex, strCurrentClasses, selectedMenuHasSubItems } =
        action.payload;
      const currentClasses = strCurrentClasses
        ? strCurrentClasses.split(' ').filter((x) => x !== '')
        : '';
      let nextClasses = '';
      if (!selectedMenuHasSubItems) {
        if (
          currentClasses.includes('menu-default') &&
          (clickIndex % 4 === 0 || clickIndex % 4 === 3)
        ) {
          clickIndex = 1;
        }
        if (currentClasses.includes('menu-sub-hidden') && clickIndex % 4 === 2) {
          clickIndex = 0;
        }
        if (
          currentClasses.includes('menu-hidden') &&
          (clickIndex % 4 === 2 || clickIndex % 4 === 3)
        ) {
          clickIndex = 0;
        }
      }

      if (clickIndex % 4 === 0) {
        if (
          currentClasses.includes('menu-default') &&
          currentClasses.includes('menu-sub-hidden')
        ) {
          nextClasses = 'menu-default menu-sub-hidden';
        } else if (currentClasses.includes('menu-default')) {
          nextClasses = 'menu-default';
        } else if (currentClasses.includes('menu-sub-hidden')) {
          nextClasses = 'menu-sub-hidden';
        } else if (currentClasses.includes('menu-hidden')) {
          nextClasses = 'menu-hidden';
        }
        clickIndex = 0;
      } else if (clickIndex % 4 === 1) {
        if (
          currentClasses.includes('menu-default') &&
          currentClasses.includes('menu-sub-hidden')
        ) {
          nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
        } else if (currentClasses.includes('menu-default')) {
          nextClasses = 'menu-default sub-hidden';
        } else if (currentClasses.includes('menu-sub-hidden')) {
          nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
        } else if (currentClasses.includes('menu-hidden')) {
          nextClasses = 'menu-hidden main-show-temporary';
        }
      } else if (clickIndex % 4 === 2) {
        if (
          currentClasses.includes('menu-default') &&
          currentClasses.includes('menu-sub-hidden')
        ) {
          nextClasses = 'menu-default menu-sub-hidden sub-hidden';
        } else if (currentClasses.includes('menu-default')) {
          nextClasses = 'menu-default main-hidden sub-hidden';
        } else if (currentClasses.includes('menu-sub-hidden')) {
          nextClasses = 'menu-sub-hidden sub-hidden';
        } else if (currentClasses.includes('menu-hidden')) {
          nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
        }
      } else if (clickIndex % 4 === 3) {
        if (
          currentClasses.includes('menu-default') &&
          currentClasses.includes('menu-sub-hidden')
        ) {
          nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
        } else if (currentClasses.includes('menu-default')) {
          nextClasses = 'menu-default sub-hidden';
        } else if (currentClasses.includes('menu-sub-hidden')) {
          nextClasses = 'menu-sub-hidden sub-show-temporary';
        } else if (currentClasses.includes('menu-hidden')) {
          nextClasses = 'menu-hidden main-show-temporary';
        }
      }
      if (currentClasses.includes('menu-mobile')) {
        nextClasses += ' menu-mobile';
      }
      state.containerClassnames = nextClasses;
      state.menuClickCount = clickIndex;
    },
  },
});

export const {
  changeSelectedMenuHasSubItems,
  changeDefaultClassnames,
  addContainerClassname,
  clickOnMobileMenu,
  setContainerClassnames,
} = menuSlice.actions;

export default menuSlice.reducer;
