/* eslint-disable global-require */

// Import Bootstrap CSS
import './assets/css/vendor/bootstrap.min.css';

// Import Bootstrap RTL CSS
import './assets/css/vendor/bootstrap.rtl.only.min.css';

// Import CSS for react-circular-progressbar
import 'react-circular-progressbar/dist/styles.css';

// Import CSS for react-perfect-scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// Import CSS for react-big-calendar
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Import CSS for react-image-lightbox
import 'react-image-lightbox/style.css';

// Import CSS for video.js
import 'video.js/dist/video-js.css';

// Loading the CSS and setting up the theme
const render = () => {
  // Dynamically import a specific theme based on a string template
  import(`./assets/css/sass/themes/gogo.${'light.purplemonster'}.scss`).then(() => {
    // Require the AppRenderer module
    require('./AppRenderer');
  });
};

// Call the render function to load the CSS and set the theme
render();
