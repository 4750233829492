

export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;





export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
// export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
// export const servicePath = 'https://api.coloredstrategies.com';


export const themeRadiusStorageKey = '__theme_radius';


