
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeQuery: {},
  chats: [],
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActiveQuery: (state, action) => {
      console.log(action.payload,'seting the active query')
      state.activeQuery = action.payload;
    },
    appendChat: (state, action) => {
      console.log(action.payload)
      state.chats.push(action.payload);
    },
    setMultipleChats:(state,actions)=>{
        state['chats']=actions.payload;
    },
    clearChats: (state) => {
      state.chats = [];
    },
  },
});

export const { setActiveQuery, appendChat, clearChats,setMultipleChats } = chatSlice.actions;
export default chatSlice.reducer;